import React from "react";
import Link from "gatsby-link";
import Scaffold from "../../../components/portfolio";

import clarity from "../../../images/clarity.png";

const Clarity = (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Clarity Coach</h2>

    <h3>Overview</h3>

    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div>
        <a href="http://clarity.coach/" target="_blank">
          <div className="browser-mockup">
            <img style={{ maxWidth: "100%", height: 180 }} src={clarity} />
          </div>
        </a>
      </div>
    </div>

    <p>
      I am the founder of Clarity Coach, an AI-powered startup coach for the
      e-sport game Dota 2. The subscription service reviews every game a user
      plays, and offers them personalised, detailed advice on how they can best
      improve at the game.
    </p>
    <p>
      Clarity Coach is a paid service with a free tier, and I have grown the
      service to hundreds of active users.
    </p>

    <div />

    <Link to="/portfolio/clarity/plan/">Next</Link>
  </Scaffold>
);

export default Clarity;
